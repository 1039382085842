var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"viewDashBoard project-route-index"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"},on:{"beforeLeave":_vm.beforeLeave,"enter":_vm.enter,"afterEnter":_vm.afterEnter}},[(_vm.loader)?_c('div',{key:"1",staticClass:"white-row loader-route"},[_c('div',{staticClass:"lds-sub-ripple"},[_c('div'),_c('div')])]):_c('div',{staticClass:"white-row"},[_c('div',{staticClass:"show-project-portal-listing"},[_c('div',{staticClass:"form-area"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row top-gen-heading"},[_c('h1',{staticClass:"global-style-heading"},[_vm._v(" Create Brand ")])])]),_c('div',{staticClass:"col-sm-12"},[_c('form',{staticClass:"brand-form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"brand_name"}},[_vm._v("Brand Name: "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.brand_name),expression:"formData.brand_name"}],staticClass:"form-control",class:{
                              'input-border-errors':
                                _vm.$v.formData.brand_name.$dirty &&
                                !_vm.$v.formData.brand_name.required
                            },attrs:{"type":"text","placeholder":"Brand Name"},domProps:{"value":(_vm.formData.brand_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "brand_name", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"brand_prefix"}},[_vm._v("Brand Prefix: "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.brand_prefix),expression:"formData.brand_prefix"}],staticClass:"form-control",class:{
                              'input-border-errors':
                                _vm.$v.formData.brand_prefix.$dirty &&
                                !_vm.$v.formData.brand_prefix.required
                            },attrs:{"type":"text","placeholder":"Brand Prefix"},domProps:{"value":(_vm.formData.brand_prefix)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "brand_prefix", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"brand_url"}},[_vm._v("Brand URL: "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.brand_url),expression:"formData.brand_url"}],staticClass:"form-control",class:{
                              'input-border-errors':
                                _vm.$v.formData.brand_url.$dirty &&
                                (!_vm.$v.formData.brand_url.required ||
                                  !_vm.$v.formData.brand_url.url)
                            },attrs:{"type":"text","placeholder":"Enter Url"},domProps:{"value":(_vm.formData.brand_url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "brand_url", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"brand_image_url"}},[_vm._v("Brand Logo URL: "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.brand_image_url),expression:"formData.brand_image_url"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Enter Url"},domProps:{"value":(_vm.formData.brand_image_url)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "brand_image_url", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"brand_contact"}},[_vm._v("Brand Contact: "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.brand_contact),expression:"formData.brand_contact"}],staticClass:"form-control",class:{
                              'input-border-errors':
                                _vm.$v.formData.brand_contact.$dirty &&
                                !_vm.$v.formData.brand_contact.required
                            },attrs:{"type":"text","placeholder":"Brand Contact"},domProps:{"value":(_vm.formData.brand_contact)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "brand_contact", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"currency_id"}},[_vm._v("Brand Currency "),_c('span',[_vm._v("*")])]),_c('Select2',{class:{
                              'input-border-errors':
                                _vm.$v.formData.currency_id.$dirty &&
                                !_vm.$v.formData.currency_id.required
                            },attrs:{"options":_vm.currency_options,"settings":{
                              placeholder: 'Select Currency'
                            }},model:{value:(_vm.formData.currency_id),callback:function ($$v) {_vm.$set(_vm.formData, "currency_id", $$v)},expression:"formData.currency_id"}})],1)])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"gender"}},[_vm._v("Select Account Manager"),_c('span',[_vm._v("*")])]),_c('Select2',{class:{
                              'input-border-errors':
                                _vm.$v.formData.brand_am.$dirty &&
                                !_vm.$v.formData.brand_am.required
                            },attrs:{"options":_vm.am_options,"settings":{
                              placeholder: 'Select Account Manager',
                              allowClear: true
                            }},model:{value:(_vm.formData.brand_am),callback:function ($$v) {_vm.$set(_vm.formData, "brand_am", $$v)},expression:"formData.brand_am"}})],1)])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"brand_front_team"}},[_vm._v("Brand Front Team")]),_c('Select2',{attrs:{"options":_vm.am_options,"settings":{
                              placeholder: 'Brand Front Team',
                              allowClear: true,
                              multiple : true,
                            }},model:{value:(_vm.formData.brand_front_team),callback:function ($$v) {_vm.$set(_vm.formData, "brand_front_team", $$v)},expression:"formData.brand_front_team"}})],1)])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"brand_address"}},[_vm._v("Brand Address: "),_c('span',[_vm._v("*")])]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.brand_address),expression:"formData.brand_address"}],staticClass:"form-control",class:{
                              'input-border-errors':
                                _vm.$v.formData.brand_address.$dirty &&
                                !_vm.$v.formData.brand_address.required
                            },attrs:{"placeholder":"Brand Address"},domProps:{"value":(_vm.formData.brand_address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "brand_address", $event.target.value)}}})])])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"terms_and_condition"}},[_vm._v("Terms and Condtition "),_c('span',[_vm._v("*")])]),_c('ckeditor',{class:{
                              'input-border-errors':
                                _vm.$v.formData.terms_and_condition.$dirty &&
                                !_vm.$v.formData.terms_and_condition.required
                            },attrs:{"config":_vm.editorConfig},model:{value:(_vm.formData.terms_and_condition),callback:function ($$v) {_vm.$set(_vm.formData, "terms_and_condition", $$v)},expression:"formData.terms_and_condition"}})],1)])]),_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-actions"},[_c('button',{staticClass:"btn btn-primary default-global-btn",attrs:{"disabled":_vm.disableSubmitBtn,"type":"submit","id":"save-form"}},[_vm._v(" Create ")]),(_vm.disableSubmitBtn)?_c('div',{staticClass:"form_submit_loader"},[_c('div',{staticClass:"lds-ellipsis"},[_c('div'),_c('div'),_c('div'),_c('div')])]):_vm._e()])])])])])])])])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }